<template>
  <modal-padrao
    ref="modal-visualizar-instrumentos"
    :max-width="EhTelaPequena ? '90%' : '50%'"
    :titulo="$t('modulos.roteiro_execucao_envio_material.modal.instrumento')"
  >
    <div style="min-height: 200px">
      <tabela-padrao-prime-vue
        v-model="selecionados"
        :dados="tabela.dados"
        filtro-geral
        class="mt-2"
        :mostrar-seletor="false"
        :colunas="tabela.colunas"
        sem-paginacao
        :filters="filters"
        ajustar-altura-linha
        :mostrar-acoes="false"
        :global-filters="['codigo', 'tipoInstrumento']"
      />
    </div>
    <template v-slot:botoes>
      <botao-padrao
        color="primary"
        outlined
        class="mr-2"
        @click="fecharModal()"
      >
        {{ $t('modulos.roteiro_execucao_envio_material.modal.voltar') }}
      </botao-padrao>
    </template>
  </modal-padrao>
</template>
<script>
import { FilterMatchMode } from 'primevue/api';

export default {
  props: {
    participanteId: { type: String, default: null },
  },
  data() {
    return {
      filtro: {
        codigo: '',
      },
      filtroAplicado: { filter: '' },
      selecionados: [],
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.roteiro_execucao_envio_material.entregaRecebimento.modalInstrumento.codigo'),
            sortable: true,
          },
          {
            value: 'tipoInstrumento',
            text: this.$t('modulos.roteiro_execucao_envio_material.entregaRecebimento.modalInstrumento.tipoInstrumento'),
            sortable: true,
          },
        ],
      },
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    EhTelaPequena() {
      return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    abrirModal: function (envioMaterial) {
      this.tabela.dados = envioMaterial.instrumentos?.map((instrumento) => {
        return {
          envioMaterial: envioMaterial.codigo,
          codigo: instrumento.codigo,
          tipoInstrumento: instrumento.tipoInstrumento,
        };
      });
      this.$refs['modal-visualizar-instrumentos'].abrirModal();
    },
    fecharModal() {
      this.$refs['modal-visualizar-instrumentos'].fecharModal();
    },
  },
};
</script>
