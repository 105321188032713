<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2 col-12"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      sem-paginacao
      sem-acoes
      :mobile-breakpoint="1"
    >
      <template v-slot:item.situacao="{ item }">
        <span :class="enumLidoNaoLido(item.item?.situacao)?.cor + '--text'">
          {{ enumLidoNaoLido(item.item?.situacao)?.text }}
        </span>
      </template>
    </tabela-padrao>
    <div
      :class="{'d-flex mx-3 mb-3': true,
               'justify-end': !EhTelaPequena,
               'justify-center': EhTelaPequena,
               'flex-column justify-center justify-md-end': EhTelaPequena}"
    >
      <botao-padrao
        :btn100="EhTelaPequena"
        :class="{'flex flex-grow-1': EhTelaPequena, 'mr-2': !EhTelaPequena, 'mb-2': EhTelaPequena}"
        @click="abrirScanQrCode"
      >
        <v-icon
          class="mr-2"
          size="25"
        >
          $mdiQrcode
        </v-icon>
        {{
          $t(
            'modulos.roteiro_execucao_envio_material.botoes.escaneie_qrcode'
          )
        }}
      </botao-padrao>
      <botao-padrao
        :btn100="EhTelaPequena"
        outlined
        :class="{'flex flex-grow-1': EhTelaPequena}"
        color="secondary"
        @click="abrirVisualizarInstrumentos"
      >
        <v-icon class="mr-2">
          $eyeOutline
        </v-icon>
        {{
          $t(
            'modulos.roteiro_execucao_envio_material.botoes.visualizarInstrumentos'
          )
        }}
      </botao-padrao>
    </div>
    <v-form class="row">
      <input-text
        v-model="nomeCliente"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.entregaRecebimento.formulario.cliente'
          )
        "
        class="col-12"
        disabled
      />
      <input-select
        v-model="form.contatoAtendimento"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.entregaRecebimento.formulario.contato'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        :input-adicionar="true"
        obrigatorio
        com-botao-adicionar
        class="col-12"
        :options="opcoes.contato"
        retornar-objeto
      >
        <template #botaoAdicionar>
          <botao-padrao
            :botao-adicionar-input="true"
            @click="abrirCadastroParticipante"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-select>
      <input-text
        v-model="form.notaFiscal"
        :label="$t('modulos.envio_material.formulario.nota_fiscal')"
        type="number"
        :max="50"
        class="col-12"
      />
      <input-textarea
        v-model="form.observacao"
        class="col-12"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.entregaRecebimento.formulario.observacao'
          )
        "
        :obrigatorio="false"
      />
    </v-form>
    <div class="d-flex" />
    <v-divider class="my-5" />
    <div :class="{'d-flex': true, 'justify-end': !EhTelaPequena, 'flex-column justify-center justify-md-end': EhTelaPequena}">
      <!-- class="flex-grow-1 flex-md-grow-0 mb-2" -->
      <botao-padrao
        :class="{'mb-2': EhTelaPequena,'mr-2': !EhTelaPequena}"
        :btn100="EhTelaPequena"
        :disabled="podeFazerCheckout"
        @click="checkout('Normal')"
      >
        <v-icon class="mr-2">
          $fileCheckOutline
        </v-icon>
        {{ $t('modulos.roteiro_execucao_envio_material.botoes.checkOut') }}
      </botao-padrao>
      <!-- class="flex flex-grow-1 mb-2" -->
      <botao-padrao
        :class="{'mb-2': EhTelaPequena,'mr-2': !EhTelaPequena}"
        outlined
        color="secondary"
        :btn100="EhTelaPequena"
        @click="abrirCheckoutPorReagendamentoOuCancelamento('reagendamento')"
      >
        <v-icon class="mr-2">
          $calendar
        </v-icon>
        {{
          $t(
            'modulos.roteiro_execucao_envio_material.botoes.checkOutReagendamento'
          )
        }}
      </botao-padrao>
      <!-- class="flex flex-grow-1 mb-2" -->
      <botao-padrao
        :class="{'mb-2': EhTelaPequena,'mr-2': !EhTelaPequena}"
        outlined
        color="error"
        :btn100="EhTelaPequena"
        @click="abrirCheckoutPorReagendamentoOuCancelamento('cancelamento')"
      >
        <v-icon class="mr-2">
          $closeCircleOutline
        </v-icon>
        {{
          $t(
            'modulos.roteiro_execucao_envio_material.botoes.checkOutCancelamento'
          )
        }}
      </botao-padrao>
      <!-- class="flex flex-grow-1 mb-2" -->
      <botao-padrao
        :class="{'mb-2': EhTelaPequena,'mr-2': !EhTelaPequena}"
        outlined
        color="secondary"
        :btn100="EhTelaPequena"
        @click="receberSemAgendar"
      >
        <v-icon class="mr-2">
          $plus
        </v-icon>
        {{
          $t(
            'modulos.roteiro_execucao_envio_material.botoes.checkOutSemAgendamento'
          )
        }}
      </botao-padrao>
      <!-- class="flex flex-grow-1" -->
      <botao-padrao
        class="mb-2"
        color="error"
        :btn100="EhTelaPequena"
        @click="desfazerCheckin"
      >
        <v-icon class="mr-2">
          $closeCircleOutline
        </v-icon>
        {{
          $t('modulos.roteiro_execucao_envio_material.botoes.desfazerCheckin')
        }}
      </botao-padrao>
    </div>
    <contato-modal
      ref="contato-modal"
      :participante-id="clienteDaVez.id"
      @atualizar-contato="atualizarContato"
    />
    <visualizar-instrumento-modal
      ref="modal-visualizar-instrumentos"
    />
    <ScanQRCode
      ref="modal-scan-qrcode"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { orderBy, findIndex } from 'lodash';
import {
  RoteiroEnvioMaterialService,
  ParticipanteService,
} from '@/common/services/cadastros';
import { RoteiroExecutarEntregaRecebimentoModel } from '../../../../common/models/cadastros/RoteiroExecutarEntregaRecebimentoModel';
import { DropdownModel } from '../../../../common/models/geral/DropdownModel';
import ContatoModal from './modal/ContatoParticiapante';
import VisualizarInstrumentoModal from './modal/VisualizarInstrumentos.vue'
import { EventTypes, useEmitterBus } from '@plugins/emitter-bus.js'
import helpers from '@common/utils/helpers'
import ScanQRCode from './modal/ScanQRCode.vue';

export default {
  components: {
    ContatoModal,
    VisualizarInstrumentoModal,
    ScanQRCode
  },
  data() {
    return {
      form: new RoteiroExecutarEntregaRecebimentoModel({}),
      opcoes: {
        contato: [],
      },
      nomeCliente: '',
      envioMaterial: {},
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t(
              'modulos.roteiro_execucao_envio_material.entregaRecebimento.codigo'
            ),
            sortable: false,
          },
          {
            value: 'quantidadeVolumes',
            text: this.$t(
              'modulos.roteiro_execucao_envio_material.entregaRecebimento.volume'
            ),
            sortable: false,
          },
          {
            value: 'situacao',
            text: this.$t(
              'modulos.roteiro_execucao_envio_material.entregaRecebimento.situacao'
            ),
            sortable: false
          }
        ],
      },
      emitterGlobal: useEmitterBus(),
    };
  },
  computed: {
    ...mapGetters('Roteiro', ['clienteDaVez', 'roteiroId']),
    tituloFormulario() {
      if (this.envioMaterial.tipoEnvioMaterial === 'Entrega')
        return this.$t(
          'modulos.roteiro_execucao_envio_material.titulos.entrega',
          { codigo: this.envioMaterial.codigo }
        );

      return this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.recebimento',
        { codigo: this.envioMaterial.codigo }
      );
    },
    podeFazerCheckout() {
      if (this.tabela.selecionados?.length == this.tabela.dados?.length)
        return false;
      if (this.form.observacao?.length > 0) return false;
      return true;
    },
    EhTelaPequena() {
      return ['sm', 'xs', 'md'].includes(this.$vuetify.breakpoint.name);
    },
  },

  mounted: async function () {
    await this.atualizarRoteiro();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.EntregaRecebimento'
      )
    );
  },
  methods: {
    abrirScanQrCode(){
      this.$refs['modal-scan-qrcode'].abrirModal();
    },
    carregarInformacoes(){
      const enviosMateriaisOrdenado = orderBy(
        this.clienteDaVez.enviosMateriais,
        ['tipoEnvioMaterial'],
        ['situacao']
      );

      const envioMaterialDaVezIndex = findIndex(enviosMateriaisOrdenado, {
        situacao: 'EmExecucao',
      });

      this.envioMaterial = enviosMateriaisOrdenado[envioMaterialDaVezIndex];

      this.$store.dispatch(
        'Roteiro/atualizarEnvioMaterialDaVez',
        this.envioMaterial
      );

      this.form = new RoteiroExecutarEntregaRecebimentoModel(this.envioMaterial);
      this.opcoes.contato = this.clienteDaVez.contatos?.map(
        (contato) => new DropdownModel(contato)
      );

      if (!this.form.contatoAtendimento) {
        this.form.contatoAtendimento = new DropdownModel(
          this.envioMaterial.contatoSolicitante
        );
      }

      this.nomeCliente = this.clienteDaVez.nome;

      this.montarDadosTabela();

      this.registrarEventGlobal();
    },
    montarDadosTabela(){
      this.tabela.dados = this.envioMaterial?.qrCodes?.map(qrcode => {
        if(qrcode.lido) this.verificarQRCodeLido(qrcode.id)
        return {
          id: qrcode.id,
          codigo: this.envioMaterial.codigo,
          quantidadeVolumes: qrcode.volume,
          situacao: qrcode?.lido,
        }
      })
    },
    enumLidoNaoLido(v){
      const situacao = helpers.LidoNaoLidoEnum?.find(el => el.value === v);
      return situacao;
    },
    registrarEventGlobal(){
      this.emitterGlobal.listen(EventTypes.QRCodeEnvioMaterial, id => {
        this.verificarQRCodeLido(id)
        this.atualizarRoteiro()
      })
    },
    verificarQRCodeLido: function (id) {
      if(this.tabela.selecionados.some(item => item.id === id)) return;

      const qrCodeTabela = this.tabela.dados.find(qrcode => qrcode.id === id);
      const qrCode = this.envioMaterial.qrCodes.find(qrcode => qrcode.id === id);

      if(qrCodeTabela) qrCodeTabela.situacao = true;
      qrCode.lido = true;

      this.tabela.selecionados.push(qrCode)
    },
    abrirVisualizarInstrumentos: function () {
      this.$refs['modal-visualizar-instrumentos'].abrirModal(this.envioMaterial);
    },
    checkout(tipoCheckout) {
      this.form.tipoCheckout = tipoCheckout;
      RoteiroEnvioMaterialService.checkOutCliente(
        this.roteiroId,
        this.envioMaterial.id,
        this.form.request
      ).then(() => {
        this.$store.dispatch('Roteiro/limparClienteDaVez');
        this.$router.push({ name: 'roteiro-executar-envio-material-checkin' });
      });
    },
    abrirCadastroParticipante: function () {
      this.$refs['contato-modal'].abrirModal();
    },
    atualizarContato: function () {
      ParticipanteService.buscar(this.clienteDaVez.id).then((res) => {
        this.opcoes.contato = res.data.contatos.map(
          (contato) => new DropdownModel(contato)
        );
        this.form.contatoAtendimento = this.opcoes.contato.at(-1);
      });
    },
    receberSemAgendar: function () {
      this.$router.push({
        name: 'roteiro-executar-envio-material-recebimento-sem-agendamento',
      });
    },
    abrirCheckoutPorReagendamentoOuCancelamento: function (tipoCheckout) {
      this.$router.push({
        name: `roteiro-executar-envio-material-checkout-por-${tipoCheckout}`,
      });
    },
    desfazerCheckin: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.roteiro_execucao_envio_material.popup.desfazerCheckin')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        RoteiroEnvioMaterialService.desfazerCheckInCliente(
          this.roteiroId,
          this.clienteDaVez.id
        )
          .then(() => {
            this.$store.dispatch('Roteiro/limparClienteDaVez');
            this.$router.push({
              name: 'roteiro-executar-envio-material-checkin',
            });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    atualizarRoteiro: async function(){
      await RoteiroEnvioMaterialService.buscarRoteiroDia()
      .then(async (res) => {
        const roteiro = res.data;
        let clienteDaVez = null;
        this.$store.dispatch('Roteiro/atualizarRoteiro', roteiro);

        const temClienteEmExecucaoIndex = findIndex(roteiro.clientes, {
          situacao: 'EmExecucao',
        });

        if (temClienteEmExecucaoIndex != -1) {
          clienteDaVez = roteiro.clientes[temClienteEmExecucaoIndex];
          await this.$store
            .dispatch('Roteiro/atualizarClienteDaVez', clienteDaVez)
            .then(async () => {
              await this.$store.dispatch(
                'Roteiro/atualizarClienteDaVezIndex',
                temClienteEmExecucaoIndex
              );
            });
        }

        const indiceClienteDaVez = findIndex(roteiro.clientes, {
          situacao: 'EmExecucao',
        });

        clienteDaVez = roteiro.clientes[indiceClienteDaVez];

        await this.$store.dispatch(
          'Roteiro/atualizarClienteDaVezIndex',
          indiceClienteDaVez
        );

        await this.$store.dispatch(
          'Roteiro/atualizarClienteDaVez',
          clienteDaVez
        );
      })
      .finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
        this.carregarInformacoes();
      });
    }
  },
};
</script>
