<template>
  <modal-padrao
    ref="modal-scan-qrcode"
    :max-width="EhTelaPequena ? '90%' : '50%'"
    :titulo="'QRCode'"
    :mostrar-botoes="false"
    @modal-fechado="modalAberto = false"
  >
    <div v-if="modalAberto">
      <QrcodeStream
        :track="paintOutline"
        :constraints="{ facingMode }"
        @init="onInit"
        @detect="onDetect"
      >
        <button @click="switchCamera">
          <v-icon
            size="60"
            color="white"
          >
            $mdiCameraRetakeOutline
          </v-icon>
        </button>
        <div
          v-if="error"
          class="div-code-stream"
        >
          <span class="span-error-camera"> Erro ao acessar a câmera </span>
          <span
            v-if="errorHttps"
            class="span-error-https"
          >
            O QRCode só pode ser lido em ambiente seguro (HTTPS)
          </span>
        </div>
      </QrcodeStream>
    </div>
  </modal-padrao>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import EnvioMaterialService from '@/common/services/cadastros/EnvioMaterialService.js';

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      modalAberto: false,
      facingMode: 'environment',
      noRearCamera: false,
      noFrontCamera: false,
      error: false,
      errorHttps: false,
    };
  },
  computed: {
    EhTelaPequena() {
      return ['sm', 'xs'].includes(this.$vuetify.breakpoint.name);
    },
  },
  methods: {
    onDetect(v) {
      v.then((result) => {
        const dados = this.desmembrarURLQrCode(result.content);

        EnvioMaterialService.lerQRCode(dados.envioMaterialId, dados.qrCodeId)
          .then(() => {
            this.toastSucesso(
              this.$t(
                'modulos.roteiro_execucao_envio_material.mensagem.QRLidoSucesso'
              )
            );
          })
          .catch(() => {
            this.toastErro(
              this.$t(
                'modulos.roteiro_execucao_envio_material.mensagem.QRInvalido'
              )
            );
          });
      }).catch(() => {
        console.log('erro ao ler qr code');
      });
    },
    desmembrarURLQrCode(url) {
      const envioMaterialId = url
        .substring(url.indexOf('envio-material'))
        .split('/')[1];
      const qrCodeId = url.substring(url.indexOf('qrcode')).split('/')[1];

      return { envioMaterialId, qrCodeId };
    },
    fecharModal() {
      this.$refs['modal-scan-qrcode'].fecharModal();
    },
    abrirModal: async function () {
      this.modalAberto = true;
      await this.$nextTick();
      this.$refs['modal-scan-qrcode'].abrirModal();
    },
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = 'red';

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    async onInit(promise) {
      promise.catch((error) => {
        this.error = true;
        if (error.name === 'NotAllowedError') {
          console.log('NotAllowedError');
        } else if (error.name === 'NotFoundError') {
          console.log('NotFoundError');
        } else if (error.name === 'NotSupportedError') {
          console.log('NotSupportedError');
        } else if (error.name === 'NotReadableError') {
          console.log('NotReadableError');
        } else if (error.name === 'OverconstrainedError') {
          console.log('OverconstrainedError');
        } else if (error.name === 'StreamApiNotSupportedError') {
          console.log('StreamApiNotSupportedError');
        } else if (error.name === 'InsecureContextError') {
          this.errorHttps = true;
        }
      });
    },
    switchCamera() {
      switch (this.facingMode) {
        case 'environment':
          this.facingMode = 'user';
          break;
        case 'user':
          this.facingMode = 'environment';
          break;
      }
    },
  },
};
</script>
<style scoped>
.div-code-stream {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.span-error-camera {
  font-size: 20px !important;
  font-weight: 600;
  text-align: center;
}
</style>
